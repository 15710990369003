
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '14',
                'height': '14',
                'viewBox': '0 0 14 14',
                'fill': 'none'
            }, _createElement('path', {
                'd': 'M11.5625 10.25L11 10L10.6263 10.0138C11.5134 8.98477 12.001 7.67111 12 6.3125C12 5.18762 11.6664 4.088 11.0415 3.1527C10.4165 2.21739 9.52827 1.48841 8.48901 1.05794C7.44976 0.627464 6.30619 0.514833 5.20293 0.734286C4.09966 0.95374 3.08624 1.49542 2.29083 2.29083C1.49542 3.08624 0.95374 4.09966 0.734286 5.20293C0.514833 6.30619 0.627464 7.44976 1.05794 8.48901C1.48841 9.52827 2.21739 10.4165 3.1527 11.0415C4.088 11.6664 5.18762 12 6.3125 12C7.72125 12 9.01625 11.4838 10.0138 10.6263L10 11L10.25 11.5625L12.6962 13.8038L14 12.5L11.5625 10.25ZM6.3125 10.25C4.13375 10.25 2.375 8.49125 2.375 6.3125C2.375 4.13375 4.13375 2.375 6.3125 2.375C8.49125 2.375 10.25 4.13375 10.25 6.3125C10.25 8.49125 8.49125 10.25 6.3125 10.25Z',
                'fill': '#585B2F'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]