export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'home-featured home-content',

  FACET_PANEL_CLASS: 'page-sidebar',

  MAIN_CONTENT_CLASS: 'page-content',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'form-button',
  SEARCH_BOX_PLACEHOLDER: "Search what you're looking for...",
};
